import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Slider from "react-slick"
import Quality_work from "../images/quality-work.png"
import co_work from "../images/co-work.png"
import theme_parties from "../images/theme-parties.png"
import teamgroup from "../images/team-group.png"

import Quality_Work from "../images/quality_icon.png"
import Brilliant_CoWorkers from "../images/co-workers.png"
import Awesome_Food from "../images/awesome_food.png"
import Games from "../images/games.png"
import Theme_Parties from "../images/theme_parties.png"
import Sakura from "../images/skura.png"

import {Helmet} from "react-helmet/es/Helmet"
import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

const reason_slider = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                autoplay: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
            },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ],
};

export default class Career extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            job: "",
            response:"",
            displayResult:false,
            displayClass:'',
        };
        this.jobTitle = React.createRef();
    }

    handleJobClick = event => {
      this.setState({
          job:event.target.innerHTML
      });
      //this.jobTi.current.focus();
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        })
    };

    handleSubmit = event => {
        event.preventDefault();

        let HUBSPOT_CONTACT_FORM = 'https://api.hsforms.com/submissions/v3/integration/submit/6881310/7f61437c-71eb-4c0c-b4af-aa31d38dd401';
        let data = {
            "submittedAt": new Date().getTime(),
            "fields": [
                {
                    "name": "email",
                    "value": this.state.email
                },
                {
                    "name": "name",
                    "value": this.state.name
                },
                {
                    "name": "jobtitle",
                    "value": this.state.job
                },
                {
                    "name": "mobilephone",
                    "value": this.state.phone
                }

            ],

            "legalConsentOptions": { // Include this object when GDPR options are enabled
                "consent": {
                    "consentToProcess": true,
                    "text": "I agree to allow Example Company to store and process my personal data.",
                    "communications": [
                        {
                            "value": true,
                            "subscriptionTypeId": 999,
                            "text": "I agree to receive marketing communications from Example Company."
                        }
                    ]
                }
            }
        };

        const final_data = JSON.stringify(data);

        // try {
        let _message = '';
        let _this = this;

        fetch(HUBSPOT_CONTACT_FORM, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: final_data
            }
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                response.json().then(function (response) {
                    response.errors.forEach((data, index) => {
                        console.log(data.message,index);
                        //console.log(data, index);
                        if (_message === '') {
                            _message = data.message;
                        } else {
                            _message += '</br>' + data.message;
                        }
                    });
                    _this.setState({
                        response: _message,
                        displayResult:true,
                        displayClass:'error-msg'
                    });
                    setTimeout(() => {
                        _this.setState({ displayResult: false });
                    }, 5000);

                });
                throw Error('Missing params');
            }
        }).then((response) => {
            _this.setState({
                response: response.inlineMessage,
                services: [],
                budget: 3000,
                name: "",
                email: "",
                phone: "",
                website: "",
                about: "",
                displayResult: true,
                displayClass: 'success-msg'
            })

            setTimeout(() => {
                _this.setState({ displayResult: false });
            },5000);
        }).catch((error) => {
            console.error(error);
        })
        // } catch (e) {
        //     console.log(e);
        // }
    };

    render() {

        return <Layout>
            <Helmet>
                <title>CueBlocks: Magento Development, Website Design, Search Engine Optimization and Conversion Rate
                    Optimization
                    for eCommerce Stores | Digital Design and Marketing Agency</title>
                <meta name="viewport"
                      content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                <meta name="description"
                      content="CueBlocks is a full service interactive marketing agency that provides eCommerce development and marketing solutions through web design, development, search engine optimization, content writing, managing google analytics to online retailers. With expertise in Magento development, conversion rate optimization and various Internet marketing services, CueBlock has helped online businesses achieve a positive ROI and a progressive growth graph. "/>
                <link rel="canonical" href="https://www.cueblocks.com/work-at-cb.php"/>
                <meta property="og:site_name" content="Cueblocks"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content="https://www.cueblocks.com/services.php"/>
                <meta property="og:title"
                      content="CueBlocks eCommerce Marketing and Development Services | Certified Magento 1 and Magento 2 Service Providers"/>
                <meta property="og:description"
                      content="We offer a wide array of development services and marketing solutions for eCommerce stores running on Magento 1 and Magento 2. Get in touch with us for Magento or PHP support, customization, development, website design, content management and CRO"/>
                <meta property="og:image" content="https://www.cueblocks.com/images/services-og.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="We offer a wide array of development services and marketing solutions for eCommerce stores running on Magento 1 and Magento 2. Get in touch with us for Magento or PHP support, customization, development, website design, content management and CRO"/>
                <meta name="twitter:title"
                      content="CueBlocks eCommerce Marketing and Development Services | Certified Magento 1 and Magento 2 Service Providers"/>
                <meta name="twitter:site" content="@cueblocks"/>
                <meta name="twitter:image" content="https://www.cueblocks.com/images/services-og.jpg"/>
            </Helmet>
            <section className="career-topsection">
                <div className="container">
                    <div className="ser_sec_row_lt pd_right_40">
                        <h2 className="heading_main">work with us</h2>
                        <div className="ser_sec_row_lt_text ">
                            <p>
                              Do you think outside the box? Are you hip to the latest trends in the eCommerce industry? Do you support the idea of your furry friends
                               giving you company at work? If you answered yes, then CueBlocks is the right workspace for you. We are looking for smart, motivated, 
                               & ardent individuals who can reimagine eCommerce through design, build, and marketing strategies that help our clients stand apart from the crowd.
                                Being Magento, Shopify, Google Adwords, and MailChimp partners, we strive to deliver impeccable code, user interface, creative design, and marketing
                                 strategies that take a consumer’s eCommerce experience to the next level. 
                            </p>
                            <h3>A Little Sneak-Peek Into CueBlocks</h3>
                            
                        </div>
                    </div>
                    <div className="ser_sec_row_rt pd_left_40">
                        <iframe
                            title={"Youtube embed"}
                            width="100%"
                            height="450"
                            src="https://www.youtube.com/embed/WeHf1HQa7YA?loop=1&playlist=WeHf1HQa7YA"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="border-light"></div>
                </div>
            </section>

            <section className=" our-process reasons-work reason-to-work-wihtus">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">reasons to work with us</h2>
                <p>Still not convinced? Well, we don’t just want to leverage your expertise but we would like to make you a part of the CueBlocks culture.<br/>
                Let us tempt you with some of our irresistible offers</p>
              </div> 
                    <div className="reason-section">
                       
                        <div className="reason-slider">
                            <Slider {...reason_slider}>
                            <div className="reason-inn">
                                <figure>   <img
                                    src={Quality_Work}
                                    alt="Quality Work"
                                    className="image_responsive"
                                /></figure>
                                <h3>QUALITY WORK</h3>
                            </div>
                                <div className="reason-inn">
                                <figure>  <img
                                    src={Brilliant_CoWorkers}
                                    alt="Brilliant Co-Workers"
                                    className="image_responsive"
                                /></figure>
                                <h3>BRILLIANT CO-WORKERS</h3>
                            </div>
                            <div className="reason-inn">
                                <figure><img
                                    src={Awesome_Food}
                                    alt="Awesome Food"
                                    className="image_responsive"
                                /></figure>
                                <h3>Awesome Food</h3>
                            </div>
                            <div className="reason-inn">
                                <figure><img
                                    src={Games}
                                    alt="Games & More"
                                    className="image_responsive"
                                /></figure>
                                <h3>Games & More</h3>
                            </div>
                            <div className="reason-inn">
                                <figure><img
                                    src={Theme_Parties}
                                    alt="Celebrated Theme Parties"
                                    className="image_responsive"
                                /></figure>
                                <h3>Celebrated Theme Parties</h3>
                            </div>
                            <div className="reason-inn">
                                <figure><img
                                    src={Sakura}
                                    alt="Carlos & Sakura"
                                    className="image_responsive"
                                /></figure>
                                <h3>Carlos & Sakura</h3>
                            </div>
                            </Slider>
                    </div>
                   
                </div>
              
              {/* <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Quality_Work}
                        alt="Quality Work"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Quality Work</h3>
                    <p>
                      We don't just work but strive to accomplish more than what the clients expect from us. It's truly worth it when all our hard work and toil is rewarded 
                      with some back-patting and drinks on the house!
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Brilliant_CoWorkers}
                        alt="Brilliant Co-Workers"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Brilliant Co-Workers</h3>
                    <p>
                      You will find co-workers who don't turn into evil warheads and are usually friendlier than you can imagine. Each and everyone at CueBlocks completes
                       the jigsaw in their own special way!
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Awesome_Food}
                        alt="Awesome Food"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Awesome Food</h3>
                    <p>
                      A sumptuous, healthy meal is what we get for lunch everyday at CueBlocks. Our in-house chef keeps our hungry souls at rest with mouth-watering food everyday.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Games}
                        alt="Games & More"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Games & More</h3>
                    <p>
                      Our entertainment area is full of life, thanks to the pool table, table tennis, Foosball table, PS3 and lots of board games. We also house a funky 
                      library where Dilbert and Archies comics sit shoulder to shoulder with Freakonomics.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Theme_Parties}
                        alt="Celebrated Theme Parties"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Celebrated Theme Parties</h3>
                    <p>
                      CueBlocks brings out the best in us but not as much as the theme parties! We all put in efforts to get 'into the character' and live up to it. 
                      From prom nights to fancy dress parties; we drink, we dance, we eat, we talk and have a lot of fun!
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <ScrollAnimation animateIn="zoomIn" animateOnce="true" duration=".5">
                      <img
                        src={Sakura}
                        alt="Carlos & Sakura"
                        className="image_responsive"
                      />
                      </ScrollAnimation>
                    </figure>
                    <h3>Carlos & Sakura</h3>
                    <p>
                     Carlos and  Sakura   run around the office, play with all the employees, fight with each other and hunt for things to eat. 
                        Thought love at first sight did not exist? Come and meet them once!
                    </p>
                  </div>
                </div>

              </div>
             */}
            </div>
          </section>


            <section className="reason-to-work-wihtus">
                <div className="container">

                    <figure className="team-group">
                        <ScrollAnimation animateIn="zoomIn" animateOnce="true">
                            <img src={teamgroup} alt="CueBlocks" className="image_responsive" />
                        </ScrollAnimation>
                    </figure>


                    <div className="open-positions">
                        <p>
                            <span className="highlight-span">OPen Posts</span>
                        </p>

                        <div className="ser_sec_row_lt ">
                            <h2 className="heading_main">
                                WE ARE HIRING
                                <br/>
                                AT CUEBLOCKS
                            </h2>
                            <div className="ser_sec_row_rt ">
                                <ul>
                                    <li>
                                        <a href="#jobTitle" onClick={this.handleJobClick}>WEB DEVELOPER- MAGENTO (2)</a>
                                    </li>
                                    <li>
                                        <a href="#jobTitle" onClick={this.handleJobClick}>WEB DEVELOPER - PHP (2)</a>
                                    </li>
                                    <li>
                                        <a href="#jobTitle" onClick={this.handleJobClick}>WEB DEVELOPER - SHOPIFY</a>
                                    </li>
                                    <li>
                                        <a href="#jobTitle" onClick={this.handleJobClick}>COPYWRITER (2)</a>
                                    </li>
                                    <li>
                                        <a href="#jobTitle" onClick={this.handleJobClick}>UI DESIGNER</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="ser_sec_row_rt ">
                            <ul>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>EMAIL MARKETING SPECIALIST</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>CRO SPECIALIST (2)</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>(QA) QUALITY ANALYST</a>
                                </li>
                                <li>
                                    <a href="#jobTitle"  onClick={this.handleJobClick}>FRONT-END DEVELOPER</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>ORGANIC SEARCH SPECIALIST</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>LINUX ADMINISTRATOR</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}>WEB ANALYTICS SPECIALIST</a>
                                </li>
                                <li>
                                    <a href="#jobTitle" onClick={this.handleJobClick}   >PAID SEARCH SPECIALIST (PPC)</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form__block career-form-block"  id={'jobTitle'}>
                <div className="container">
                    <div className="contact-form__row">
                        <h2 className="heading_main">apply now!</h2>
                        <div className="form__rt">
                            <form  onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="form__row">
                                        <label>Name<em>*</em></label>
                                        <input type="text" placeholder="Enter your name"
                                               value={this.state.name}
                                               name="name"
                                               required={true}
                                               onChange={this.handleInputChange}
                                        />
                                        <div className="border-line"></div>
                                    </div>
                                    <div className="form__row">
                                        <label>Email<em>*</em></label>
                                        <input type="email"
                                               placeholder="Enter your email"
                                               name="email"
                                               value={this.state.email}
                                               onChange={this.handleInputChange}
                                        />
                                        <div className="border-line"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form__row">
                                        <label>Phone</label>
                                        <input type="text" placeholder="Enter your phone"
                                               name="phone"
                                               value={this.state.phone}
                                               onChange={this.handleInputChange}
                                        />
                                        <div className="border-line"></div>
                                    </div>
                                    <div className="form__row">
                                        <label>Job</label>
                                        <input type="text" placeholder="Enter Job title"
                                               value={this.state.job}
                                               ref={this.jobTitle}
                                               onChange={this.handleInputChange}
                                        />
                                        <div className="border-line"></div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form__row">
                                        <div className="form__block2_rt">
                                            <h2>
                                                <span>Upload</span> ANY RELEVANT FILES OR DOCUMENTS, IF
                                                REQUIRED
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__block2_rt">
                                            <div className="drop-file"></div>
                                            <p>Drop files here</p>
                                        </div>
                                    </div>
                                    <input type="submit" className="main_cta" value="Submit"/>
                                    {this.state.displayResult?
                                        <div className={this.state.displayClass}  style={{textAlign: 'center',marginTop:'10px'}} dangerouslySetInnerHTML={{__html: this.state.response}}></div>:''
                                    }
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Clutch/>
        </Layout>
    }
}
